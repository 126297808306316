@import "src/styles/media-queries.scss";
@import "src/styles/variables.scss";

.billing {
  height: 372px;

  @include min-screen($desktopLargeWidth) {
    height: 512px;
  }

  .stigg-customer-portal-subscriptions-overview {
    display: none;
  }
}
