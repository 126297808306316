.subscription-contact-support-modal {
  &__email {
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    a {
      color: #45485b;
    }
  }

  .modal__buttons {
    margin-top: 12px;
  }
}
