.plan-upgrade-needed-box {
  width: 540px;
  //   height: 562px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__image {
    width: 100%;
    height: 300px;
  }

  &__yellow-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    gap: 6px;
    padding: 4px 20px;
    background: #ffb822;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: white;
    gap: 6px;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: white;
      }
    }
  }

  &__heading {
    display: flex;
    align-items: center;

    margin: 20px 0;
    padding: 0 20px;

    &-name {
      display: inline;
      white-space: pre-line;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: #45485b;
    }

    &-icon {
      margin-right: 8px;
      width: 32px;
      height: 32px;
    }
  }

  &__text {
    padding: 0 20px;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #45485b;

    &-link {
      text-decoration: underline;
      color: #5d78ff;
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 20px;
  }
}
