.subscription-dropdown {
  &__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #6a6d7c;
  }

  &__button {
    margin-right: 12px;
    margin-left: 4px;
  }
}
