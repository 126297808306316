@import "src/styles/variables.scss";

.plan-upgrade-needed-modal {
  padding: 0;
  z-index: $z-index-max;

  .modal__buttons {
    display: none;
  }
}
