@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.trial-view {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  height: 100%;

  @include max-screen($desktopWidth) {
    margin-top: -40px;
  }

  @include min-screen($desktopWidth) {
    align-items: center;
  }

  &-footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    z-index: 0;
    margin-top: 20px;
    width: 100%;
    max-width: 1000px;

    @include max-screen($desktopWidth) {
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }

    &__back-btn {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #6a6d7c;
      width: 100%;

      @include max-screen($desktopWidth) {
        text-align: center;
      }
    }

    &__copyrights {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: right;
      color: #b4b6be;
      width: 100%;

      @include max-screen($desktopWidth) {
        text-align: center;
        margin-bottom: 10px;
      }
    }

    &-success {
      max-width: 600px !important;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    gap: 50px;
    max-width: 1000px;
    background: #fff;
    z-index: 1;
    border-radius: 20px;
    padding: 50px;

    @include max-screen($desktopWidth) {
      padding: 15px;
      flex-direction: column;
    }

    &-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: #ddd;

      @include max-screen($desktopWidth) {
        padding: 15px;
        background-color: #fff;
      }
    }

    &-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      height: 100%;

      @include max-screen($desktopWidth) {
        //display: none;
        min-height: unset;
      }

      &__icon {
        width: 48px;
      }

      &__container {
        margin-top: 24px;
        height: 100%;
      }

      &__header {
        margin-top: 12px;
        font-size: 36px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: -0.03em;
        text-align: left;
        color: #45485b;
      }

      &__author {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin: 10px 0px 30px;

        &-logo {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: contain;
        }

        &-text {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #45485b;

          &-description {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #8f919d;
          }
        }
      }

      &__ratings {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        width: 100%;

        @include max-screen($desktopWidth) {
          margin-top: 0px;
        }

        &-icon {
          height: 32px;
          margin-bottom: 15px;
          object-fit: contain;
        }

        &-text {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #45485b;
        }
      }

      &__companies {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

        img {
          width: 100%;
        }
      }

      &__how-works {
        &-title {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          text-align: left;
          color: #45485b;
          margin-bottom: 5px;
        }

        &-points {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          margin-left: 10px;
          color: #45485b;
        }
      }

      &__copyrights {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #8f919d;
      }
    }

    &-right {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 60px;

      .trial-page-right__try-again-link {
        text-decoration: underline;
        color: #45485b;
      }

      .ws-react-select__placeholder {
        font-size: 13px;
        line-height: 22px;
        top: 9px;
      }

      .ws-input-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #8f919d;
        margin-bottom: 4px;
      }

      &__wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__navigation {
        padding: 40px 40px 0 40px;
        @include max-screen($desktopWidth) {
          padding: 0;
        }
      }

      &__text-area-wrapper {
        position: relative;
      }

      &__logo {
        display: none;
      }

      &__text-area {
        padding: 12px 16px;
        resize: none;
        width: 100%;
        height: 104px;
        border: 0;
        outline: 1px solid #b4b6be;
        border-radius: 8px;
        margin-bottom: 0 !important;
        @include custom-scrollbar;
        &:focus {
          border: 0;
          outline: 1px solid rgba(93, 120, 255, 0.6);
        }
      }

      .ws-input {
        margin-bottom: 11px;
        &--validation-error {
          margin-bottom: 0;
        }
      }

      .ws-react-select .ws-react-select__multi-value {
        width: auto;

        &::after {
          content: ",";
          padding-right: 3px;
          color: #74788d;
        }
      }

      &__row {
        display: flex;
        gap: 19px;
      }

      &__column {
        width: 50%;
      }

      &__book-a-demo,
      &__back-to-homepage {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #74788d;

        span {
          white-space: nowrap;
        }

        @include max-screen($desktopWidth) {
          font-size: 16px;
          line-height: 28px;
        }
      }

      &__book-a-demo {
        &-btn {
          all: unset;
          color: #5d78ff;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;
        }
      }

      .ws-react-select__single-value {
        .trial-page-right__country-option {
          &-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 120px;
          }
        }
      }

      &__country-option {
        display: flex;
        gap: 8px;
        .flag {
          border-radius: 50%;
          width: 18px;
          height: 18px;
          object-fit: cover;
        }
      }

      .ws-react-select-wrapper {
        margin-bottom: 11px;
      }

      &__back-to-homepage {
        display: flex;
        align-items: center;
        white-space: nowrap;

        @include max-screen($desktopWidth) {
          padding: 20px;
        }

        a {
          color: #74788d;
        }
      }

      &__back-to-homepage-icon {
        margin-right: 4px;
      }

      &__header {
        font-weight: 500;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        color: #45485b;
        margin-bottom: 16px;

        &--confirm {
          margin-bottom: 48px;
        }
      }

      &__description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #45485b;
        width: 100%;
        margin-bottom: 32px;

        @include max-screen($desktopWidth) {
          width: 100%;
        }
      }

      &__disclaimer {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #585b6c;
        width: 440px;
        margin-top: 21px;
        width: 100%;

        @include max-screen($desktopWidth) {
          font-size: 14px;
          line-height: 22px;
          width: 100%;
        }

        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #5d78ff;
        }
      }

      &__user-message {
        color: #45485b;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-top: 8px;
        margin-bottom: 11px;
      }

      &__characters-left {
        &--under-limit {
          color: #57b471;
        }
        &--over-limit {
          color: #d93025;
        }
      }

      &__invalid-email-error {
        display: flex;
        align-items: center;
        color: #d93025;
        margin-bottom: 11px;
        font-size: 12px;
      }

      &__invalid-email-error-icon {
        margin-right: 4px;
      }

      &__sign-in {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #585b6c;
        margin-top: 8px;

        a {
          color: #5d78ff;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .ws-checkbox {
        margin-top: 32px;
      }

      &__text {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #6a6d7c;
        margin-bottom: 16px;
      }

      &__bold {
        font-weight: 500;
        color: #45485b;
      }

      &__email-address {
        color: #45485b;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      &__try-again {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #45485b;
        margin-bottom: 32px;
      }

      &__try-again-link {
        color: #5d78ff;
        font-weight: 500;

        &:hover {
          cursor: pointer;
        }
      }

      &__container {
        max-width: 500px;
        display: flex;
        flex-direction: column;

        @include max-screen($desktopWidth) {
          width: 100%;
          max-width: 100%;

          .trial-view-content-right__logo {
            display: block;
            margin-bottom: 40px;
            width: 140px;
          }

          form {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        &--confirm {
          align-items: center;
        }
      }

      &__confirm-wrapper {
        width: 400px;

        @include max-screen($desktopWidth) {
          width: 100%;
        }
      }

      &__vertical-line {
        border: 1px solid #ededef;
        width: 1px;
        margin: 0 auto;
        height: 176px;
      }

      &__box {
        display: flex;
        margin-bottom: 8px;
      }

      &__box-left {
        margin-right: 16px;
      }

      &__box-header-icon {
        margin-bottom: 8px;
      }

      &__box-header-text {
        color: #45485b;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      &__submit-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 32px;

        @include max-screen($desktopWidth) {
          align-items: center;

          button {
            width: 100%;
          }

          .loader-wrapper {
            margin-left: 20px !important;
          }
        }

        .loader-wrapper {
          margin: 0;
          height: 60px;
          width: auto;
          svg {
            width: 100px !important;
          }
        }
      }

      &__btn {
        padding: 12px 60px;
      }
    }

    &__registration-success {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      max-width: 500px;
      width: 100%;

      &-header {
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        color: #45485b;
        margin-top: 8px;
        max-width: 100%;
        box-sizing: border-box;
        white-space: break-spaces;

        @include max-screen($tabletWidth) {
          font-size: 24px;
          line-height: 30px;
        }
      }

      &-email {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #45485b;
        text-align: center;

        &-address {
          font-weight: 500;
        }
      }

      &-description {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #8f919d;

        &-v2 {
          font-weight: 500;
          text-align: center;
          line-height: 24px;
          color: #45485b;
        }

        &-v3 {
          margin-top: 10px;
          text-align: center;
          background: #eee;
          padding: 10px;
          font-size: 12px;
          border-radius: 10px;
        }
      }

      &-btn {
        background-color: #5d78ff;
        gap: 10px;
        border-radius: 8px;
        color: #ffffff;
        padding: 20px 40px;

        @include max-screen($desktopWidth) {
          margin-bottom: 20px;
        }
      }

      &-author {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin: 20px 0px;

        &-logo {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: contain;
        }

        &-mailto {
          margin-top: 15px;
          color: #5d78ff;
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
        }

        &-phone-number {
          margin-top: 15px;
          color: #5d78ff;
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
        }

        &-text {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #45485b;

          &-description {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #8f919d;
          }
        }
      }
    }
  }
}
