.ws-subscription {
  &-subscriptionsOverview {
    &--indahash {
      font-family: Poppins !important;

      .stigg-scheduled-updates-alert-container {
        width: 100%;
        padding: 0;
        margin-bottom: 0;
        margin: 0 !important;
        margin-bottom: 20px !important;
        padding-bottom: 20px;
      }

      .stigg-customer-portal-subscriptions-overview {
        border: none;
        padding: 0;

        .stigg-overview-layout {
          gap: 20px !important;
          flex-wrap: nowrap !important;
          flex-direction: column;
        }

        .stigg-customer-portal-overview-header {
          width: 596px;
          // flex: 1 !important;
        }

        .stigg-overview-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          width: 100px;
          color: #7d808d;
          margin-top: -19px !important;
        }

        .stigg-subscription-plan-name {
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #45485b;
          margin-top: -21px !important;
        }

        .stigg-subscription-total-price-layout {
          padding: 20px;
          background: #f6f6f7;
        }
      }
    }
  }

  &-usage {
    &--vertical-list {
      .stigg-subscription-usage-layout {
        border: none;
        padding: 0;

        &-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #7d808d;
        }

        .MuiGrid {
          &-container {
            flex-basis: auto;
            flex-direction: column;
            flex-wrap: nowrap;
            width: 100%;
            margin: 0;
            gap: 20px;
          }

          &-item {
            width: 100%;
            padding: 0;
            flex-basis: auto;
            max-width: unset;
          }
        }

        > div:first-of-type {
          margin-bottom: 20px;

          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }

  // &-paywall {
  //   &--indahash {
  //     .stigg-price-text {
  //       height: 48px;
  //     }

  //     .stigg-plan-description {
  //       height: 24px;
  //       min-height: 24px;
  //     }
  //   }
  // }

  &-paymentDetails {
    &--indahash {
      .stigg-payment-details-section-layout {
        border: none;
        padding: 0;
      }

      > div:first-of-type {
        margin-bottom: 20px;

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  &-checkout {
    &--indahash {
      .stigg-checkout-layout {
        padding: 0;
        border: 0;
      }
    }
  }
}
