@import "./normalize.scss";
@import "./variables.scss";

* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: #fff;

  &.block-ui {
    overflow: hidden;
  }

  ul,
  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  .MuiPopover-root,
  .MuiTooltip-popper {
    z-index: $z-index-max !important;
  }
}

.ws-toast {
  &.Toastify__toast-container {
    z-index: $z-index-max;
    width: 360px;
    height: fit-content;
    // margin-left: 0 !important;
    // transform: translateX(-50%) !important;
    // width: 100% !important;
    // // top: 114px !important;
    // top: 0 !important;
  }

  /** Classes for the displayed toast **/
  .Toastify__toast {
    padding: 10px;
    padding-bottom: 16px;
    border-radius: 4px;

    &--success {
      background: #e3f7f3;

      .Toastify__progress-bar {
        background: #47c4ab;
      }

      .Toastify__close-button {
        background: url("../images/toasts/close-success.svg") center;
      }

      .toast-header {
        color: #47c4ab;
      }
    }

    &--error {
      background: #ffecf2;

      .Toastify__progress-bar {
        background: #fd397a;
      }

      .Toastify__close-button {
        background: url("../images/toasts/close-error.svg") center;
      }

      .toast-header {
        color: #fd397a;
      }
    }

    &--warning {
      background: #fff8e9;

      .Toastify__progress-bar {
        background: #ffb822;
      }

      .Toastify__close-button {
        background: url("../images/toasts/close-warning.svg") center;
      }

      .toast-header {
        color: #ffb822;
      }
    }

    &--info {
      background: #e7f0ff;

      .Toastify__progress-bar {
        background: #1267fb;
      }

      .Toastify__close-button {
        background: url("../images/toasts/close-info.svg") center;
      }

      .toast-header {
        color: #1267fb;
      }
    }
  }
  .Toastify__toast--rtl {
  }
  .Toastify__toast--dark {
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--info {
    // background: linear-gradient(180deg, #82eff6 0%, #7b8ffb 100%);
  }
  .Toastify__toast--warning {
  }

  .Toastify__toast-body {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #74788d;
    display: flex;

    svg {
      margin-right: 8px;
    }

    &-header {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      margin: {
        top: 0;
        bottom: 4px;
      }
    }
  }

  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__close-button {
    color: transparent;
  }
  .Toastify__close-button--default {
  }
  .Toastify__close-button > svg {
  }
  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
  }

  /** Classes for the progress bar **/
  .Toastify__progress-bar {
    height: 4px;
    border-radius: 0 4px 4px 0;
  }
  .Toastify__progress-bar--animated {
  }
  .Toastify__progress-bar--controlled {
  }
  .Toastify__progress-bar--rtl {
  }
  .Toastify__progress-bar--default {
  }
  .Toastify__progress-bar--dark {
  }
}

.clickable {
  cursor: pointer;
}
