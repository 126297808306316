@import "src/styles/media-queries.scss";
@import "src/styles/variables.scss";

.plan {
  height: 372px;

  @include min-screen($desktopLargeWidth) {
    height: 512px;
  }
}
